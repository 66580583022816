import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
    FilesResponsePayload,
    LabFiles,
    StudentFileMetadata,
} from '../types/types';

export interface FilesSliceState {
    files: {
        [labInstance: string]: {
            metadata: StudentFileMetadata[];
        };
    };
}

const INITAL_FILES_STATE: FilesSliceState = {
    files: {},
};

export const filesSlice = createSlice({
    name: 'files',
    initialState: INITAL_FILES_STATE,
    reducers: {
        /**
         * `setFiles` takes the files response payload and sets the files state. 
         * The payload is transformed into a dictionary of courses, keyed by the
         * course catalog number (ie, SEC123). Each course has a dictionary of lab 
         * instances, keyed by the lab instance name (ie, SEC123-I01-GEN). All 
         * files related to a lab instance (general lab files _and_ range files) are 
         * stored in the metadata array of the lab instance, so files can be retrieved 
         * per instance.
         * 
         * See additonal documentation at: `docs/redux/files-slice.md`
         */
        setFiles: (state, action: PayloadAction<FilesResponsePayload>) => {
            const {payload} = action;
            const transformedPayload: {
                [labInstance: string]: {
                    metadata: StudentFileMetadata[];
                };
            } = {};

            Object.keys(payload).forEach((courseCatalog) => {
                const courseInstance = payload[courseCatalog];
                if (courseInstance) {
                    Object.keys(courseInstance).forEach(
                        (fileResponseIdentifier) => {
                            if (
                                courseInstance &&
                                courseInstance![fileResponseIdentifier]
                            ) {
                                const labFiles =
                                    courseInstance[fileResponseIdentifier];
                                if (labFiles) {
                                    const labInstance: string =
                                        labFiles.labName;

                                    if (labFiles.metadata.length > 0) {
                                        // If for some reason instance is a number, we need to convert it to str
                                        if (
                                            labFiles.instance
                                                .toString()
                                                .startsWith('jit-')
                                        ) {
                                            // If the lab files are for a range...
                                            // Create the entry if one does not yet exist
                                            if (
                                                !transformedPayload[labInstance]
                                            ) {
                                                transformedPayload[
                                                    labInstance
                                                ] = {
                                                    metadata: [],
                                                };
                                            }
                                            //append the metadata to the existing entry
                                            transformedPayload[
                                                labInstance
                                            ]!.metadata = transformedPayload[
                                                labInstance
                                            ]!.metadata.concat(
                                                labFiles.metadata
                                            );
                                        } else {
                                            // If we have metadata for this entry already (ie, a range was processed
                                            // before other files), set the metadata aside and re-concat to be safe
                                            let oldMeta: StudentFileMetadata[] =
                                                [];

                                            if (
                                                transformedPayload[
                                                    labInstance
                                                ] &&
                                                transformedPayload[labInstance]!
                                                    .metadata
                                            ) {
                                                oldMeta =
                                                    transformedPayload[
                                                        labInstance
                                                    ]!.metadata;
                                            }
                                            transformedPayload[labInstance] =
                                                labFiles;
                                            transformedPayload[
                                                labInstance
                                            ]?.metadata.concat(oldMeta);
                                        }
                                    }
                                }
                            }
                        }
                    );
                }
            });
            state.files = transformedPayload;
        },
    },
});

export const {setFiles} = filesSlice.actions;

export default filesSlice.reducer;
